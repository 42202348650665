import { render, staticRenderFns } from "./CustomerBillingAddressSelect.vue?vue&type=template&id=022a5691&scoped=true"
import script from "./CustomerBillingAddressSelect.vue?vue&type=script&lang=js"
export * from "./CustomerBillingAddressSelect.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "022a5691",
  null
  
)

export default component.exports